import moment from "moment";

const day = moment().format("MMMM D");
const christmas = moment("12-25");

export const maintenance = () => {
  //christmas

  let maintenanceFlag = false;
  /*
  if (christmas.isSame(day, "day")) {
    maintenanceFlag = true;
  } else {
    maintenanceFlag = false;
  }
    */
  //use for when ordering needs to be turned off and makre sure to change the message
  //return true;
  return maintenanceFlag;
};

export const message = () => {
  //christmas
  let message;
  if (christmas.isSame(day, "day")) {
    message = "Gold Bistro is Closed for Christmas.";
  } else {
    /*message =
      "Our online ordering system is currently going through maintenance. Please call our restaurant (403-254-6908) to place your pick-up order.";*/
    message = "Gold Bistro is Closed for Christmas.";
  }
  return message;
};

//toggle for the message banner to appear
//toggle discount message in cart in holiday.js
export const messageBannerFlag = false;
export const messageBanner = "Free Item Coupons Not Valid On New Year's Day";
