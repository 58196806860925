import React, { useEffect, useState, useRef } from "react";
import {
  Flex,
  Text,
  Icon,
  VStack,
  Button,
  HStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useAuth } from "../context/AuthContext";
import { useReactToPrint } from "react-to-print";

import io from "socket.io-client";

import { BsPersonSquare, BsClock } from "react-icons/bs";
import { AiOutlineMail, AiOutlineDollarCircle } from "react-icons/ai";
import { BiPhone, BiPrinter, BiCheckCircle } from "react-icons/bi";

import { CustomerOrderWrapper } from "../components/admin-page/CustomerOrderWrapper";
import ConfirmModal from "../components/admin-page/ConfirmModal";
import UpdateModal from "../components/admin-page/UpdateModal";

const socket = io.connect(process.env.REACT_APP_PRODUCTION);
//const socket = io.connect(process.env.REACT_APP_DEV);

const audioElement = new Audio();

function Admin(props) {
  const { admin } = useAuth();

  const {
    isOpen: isUpdateOpen,
    onOpen: onUpdateOpen,
    onClose: onUpdateClose,
  } = useDisclosure();
  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();

  const [customerOrders, setCustomerOrders] = useState([]);
  const [orderIndex, setOrderIndex] = useState(undefined);
  const [orderTime, setOrderTime] = useState();
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    let sessionData = sessionStorage.getItem("orders");
    if (JSON.parse(sessionData) !== null) {
      setCustomerOrders(JSON.parse(sessionData));
    }
  }, []);

  useEffect(() => {
    socket.on("receive_status", data => {
      //console.log("Customer order", data);
      let tempOrder = [...customerOrders];
      tempOrder.unshift(data);

      //append a customers order to a list that we map out each time a order is placed
      setCustomerOrders(tempOrder);
    });
  }, [customerOrders]);

  const playAudio = () => {
    //play audio when new order comes in
    audioElement.src = "/ringtone.mp3";
    audioElement.play();
  };

  //emit to specfic id
  const sendToAdmin = () => {
    //ready email object
    let time;
    if (orderTime === undefined) {
      //schedule xx:xx kk
      time = "at " + customerOrders[orderIndex].data.time;
    } else {
      //xx:xx Minutes!
      time = "in " + String(orderTime) + " Minutes!";
    }

    //emit time and order to server
    socket.emit("order_confirmation", {
      time: time,
      data: customerOrders[orderIndex].data,
      id: customerOrders[orderIndex].id,
    });

    setOrderTime(undefined);
  };

  const orderToggle = index => {
    audioElement.pause();
    let tempList = customerOrders.map(obj => {
      return { ...obj, toggle: false };
    });
    tempList[index].toggle = true;
    tempList[index].new = false;
    setOrderIndex(index);
    setCustomerOrders(tempList);

    //save to sessionstorage
    sessionStorage.clear();
    sessionStorage.setItem("orders", JSON.stringify(tempList));
  };

  return (
    <Flex fontFamily="body" minHeight="100vh" bg="#FAF9F9">
      <Flex direction="column" flex={1} align="center" mt="2rem">
        <Flex direction="column" width="80%">
          <Text alignSelf="flex-start" fontWeight={700} fontSize="30px">
            Customer Orders
          </Text>
          {customerOrders.length > 0 &&
            customerOrders.map((orders, i) => {
              if (orders.new) {
                playAudio();
              }
              return (
                <Flex
                  key={"customer-order" + i}
                  borderWidth="2px"
                  borderRadius="4px"
                  borderColor={orders.toggle ? "black" : "#c4c4c4"}
                  mr="1rem"
                  mt="2rem"
                  direction="column"
                  width="100%"
                  bg="white"
                  _hover={{
                    boxShadow: "base",

                    cursor: "pointer",
                  }}
                  onClick={() => orderToggle(i)}
                >
                  <Flex direction="column" m="2rem">
                    <VStack align="flex-start">
                      <Flex width="100%" align="center" justify="space-between">
                        <Text fontSize="20px">
                          Order #{customerOrders.length - i}
                        </Text>
                        <Text
                          fontWeight={700}
                          color={orders.confirmed ? "#08A045" : "#0077b6"}
                        >
                          {orders.confirmed ? "CONFIRMED" : "NEW ORDER"}
                        </Text>
                      </Flex>

                      <Flex align="center">
                        <Icon w={5} h={5} as={BsPersonSquare} />
                        <Text ml="1rem">{orders.data.name}</Text>
                      </Flex>

                      <Flex align="center">
                        <Icon w={5} h={5} as={AiOutlineMail} />
                        <Text ml="1rem">{orders.data.email}</Text>
                      </Flex>

                      <Flex align="center">
                        <Icon w={5} h={5} as={BiPhone} />
                        <Text ml="1rem">{orders.data.phone}</Text>
                      </Flex>

                      <Flex align="center">
                        <Icon w={5} h={5} as={BsClock} />
                        <Text ml="1rem">Picking Up: {orders.data.time}</Text>
                      </Flex>
                    </VStack>
                  </Flex>
                </Flex>
              );
            })}
        </Flex>
      </Flex>
      <Flex
        direction="column"
        width="60%"
        position="sticky"
        height="100%"
        top="2rem"
        mt="2rem"
        mr="4rem"
      >
        <Text fontWeight={700} fontSize="30px">
          Order Details
        </Text>

        <HStack justify="flex-end">
          <Flex>
            <Button
              width="175px"
              leftIcon={<BiPrinter />}
              variant="outline"
              onClick={handlePrint}
              bg="white"
            >
              Print
            </Button>
          </Flex>
          <Flex>
            <Button
              width="175px"
              leftIcon={<AiOutlineDollarCircle />}
              variant="outline"
              onClick={onUpdateOpen}
              bg="white"
            >
              Update
            </Button>
          </Flex>
          <Flex>
            <Button
              width="175px"
              leftIcon={<BiCheckCircle />}
              variant="outline"
              onClick={onConfirmOpen}
              bg="white"
            >
              Confirm
            </Button>
          </Flex>
        </HStack>

        <UpdateModal
          customerOrder={
            orderIndex !== undefined ? customerOrders[orderIndex] : null
          }
          isOpen={isUpdateOpen}
          onClose={onUpdateClose}
          customerOrders={customerOrders}
          orderIndex={orderIndex}
          setOrders={customerOrders => setCustomerOrders(customerOrders)}
        ></UpdateModal>

        <ConfirmModal
          customerOrder={
            orderIndex !== undefined ? customerOrders[orderIndex] : null
          }
          customerOrders={customerOrders}
          setConfirm={customerOrders => setCustomerOrders(customerOrders)}
          isOpen={isConfirmOpen}
          onClose={onConfirmClose}
          orderTime={orderTime}
          setTime={orderTime => setOrderTime(orderTime)}
          sendToAdmin={sendToAdmin}
          index={orderIndex}
        ></ConfirmModal>

        <CustomerOrderWrapper
          ref={componentRef}
          customerOrders={customerOrders}
          handlePrint={handlePrint}
        ></CustomerOrderWrapper>
      </Flex>
    </Flex>
  );
}

export default Admin;
