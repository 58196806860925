import moment from "moment";
moment().format();

export const offline = () => {
  const currentTime = moment().add(0, "minutes");
  const christmas = moment("12-25", "MM-DD");

  //online orders from 11:00AM - 1:45PM
  const startLunchTime = moment("11:00am", "hh:mm A");
  const endLunchTime = moment("1:45pm", "hh:mm A");

  //online orders from 3:00PM - 9:15PM
  const startDinnerTime = moment("3:00pm", "hh:mm A");
  const endDinnerTime = moment("9:15pm", "hh:mm A");

  //christmas hours
  //comment out after christmas
  const startChristmas = moment("3:00pm", "hh:mm A");
  const endChristmas = moment("7:45pm", "hh:mm A");

  //current day
  const day = moment().format("dddd");
  const today = moment();
  let lunchTimeFlag = currentTime.isBetween(startLunchTime, endLunchTime);
  let dinnerTimeFlag = currentTime.isBetween(startDinnerTime, endDinnerTime);

  //comment this out after christmas
  let christmasTimeFlag = currentTime.isBetween(startChristmas, endChristmas);

  let timeFlag;

  if (
    (day === "Saturday" && lunchTimeFlag) ||
    (day === "Sunday" && lunchTimeFlag)
  ) {
    timeFlag = false;
  } else if (today.isSame(christmas, "day") && christmasTimeFlag) {
    timeFlag = true;
  } else if (lunchTimeFlag || dinnerTimeFlag) {
    timeFlag = true;
  } else {
    timeFlag = false;
  }

  return timeFlag;
  //return (timeFlag = true);
};
