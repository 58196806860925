import React from "react";
import { Flex, VStack, Text, Icon, HStack } from "@chakra-ui/react";
import { PhoneIcon } from "@chakra-ui/icons";
import { FaMapMarkerAlt } from "react-icons/fa";
import { Divider } from "@chakra-ui/react";

function Footer(props) {
  return (
    <Flex
      direction="column"
      color="secondary"
      width="100%"
      pt="5rem"
      pb="1rem"
      fontFamily="innerbody"
    >
      <Flex direction={{ base: "column", lg: "row" }}>
        <HStack justify="center" mb={{ base: "2rem", lg: "0rem" }}>
          <VStack
            spacing={-1}
            color="accent"
            fontFamily="chinese"
            fontSize={{ base: "20px", md: "45px" }}
            lineHeight={{ base: "26px", md: "50px" }}
            mr="0.5rem"
          >
            <Text>金</Text>
            <Text>饭</Text>
            <Text>庄</Text>
          </VStack>
          <VStack
            align="flex-start"
            spacing={0}
            fontWeight={400}
            fontFamily="heading"
            display={{ base: "flex", lg: "none" }}
          >
            <Text
              fontSize={{ base: "16px", md: "20px" }}
              letterSpacing={2}
              lineHeight="27px"
            >
              GOLD BISTRO
            </Text>
            <Text
              fontSize={{ base: "10px", md: "12px" }}
              letterSpacing={3.7}
              lineHeight="16px"
            >
              CHINESE CUISINE
            </Text>
          </VStack>
        </HStack>

        <Flex flex={1 / 2} justify="center" mb={{ base: "2rem", lg: "0rem" }}>
          <Flex direction="column" align={{ base: "center", lg: "flex-start" }}>
            <Text fontSize="24px" mb="10px">
              Business Hours
            </Text>
            <VStack
              fontSize="15px"
              align={{ base: "center", lg: "flex-start" }}
            >
              <Text>Monday - Friday</Text>
              <Text>Lunch: 11:00AM - 2:00PM</Text>
              <Text>Monday - Sunday</Text>
              <Text>Dinner: 3:00PM - 9:30PM</Text>
            </VStack>
          </Flex>
        </Flex>

        <Flex flex={1} justify="center" mb={{ base: "2rem", lg: "0rem" }}>
          <Flex direction="column" align={{ base: "center", lg: "flex-start" }}>
            <Text fontSize="24px" mb="10px">
              Buffet Hours
            </Text>
            <VStack
              fontSize="15px"
              align={{ base: "center", lg: "flex-start" }}
            >
              <Text>Monday - Friday</Text>
              <Text>Lunch: 11:00AM - 2:00PM</Text>
              <Text>Monday - Sunday</Text>
              <Text>Dinner: 5:00PM - 8:00PM</Text>
            </VStack>
          </Flex>
        </Flex>

        <Flex
          flex={1}
          direction="column"
          align={{ base: "center", lg: "flex-start" }}
          mb={{ base: "2rem", lg: "0rem" }}
        >
          <Text fontSize="24px" mb="10px">
            Contact Us
          </Text>
          <VStack align={{ base: "center", lg: "flex-start" }} fontSize="15px">
            <Flex align="center">
              <PhoneIcon mr="10px"></PhoneIcon>
              <Text>403-254-6908</Text>
            </Flex>
            <Flex align="center">
              <Icon mr="10px" w={4} h={4} as={FaMapMarkerAlt}></Icon>
              <Text>#2-208 Midpark Way SE, Calgary AB</Text>
            </Flex>
          </VStack>
        </Flex>
      </Flex>

      <VStack mt="3rem">
        <Divider></Divider>
        <Flex
          justify={{ base: "none", md: "space-between" }}
          align={{ base: "center", md: "none" }}
          width="100%"
          color="#C4C4C4"
          direction={{ base: "column", md: "row" }}
          pt="1rem"
          pb="1rem"
        >
          <Text>© 2025 Gold Bistro</Text>
          <Text mt={{ base: "6px", md: "0px" }}>Join the Neo Culture.</Text>
        </Flex>
      </VStack>
    </Flex>
  );
}

export default Footer;
